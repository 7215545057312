import Typography from '/components/Typography';

import { reactChildren } from '/utils/prop-types';

const Table = ({ children }) => {
	return <table>{children}</table>;
};

const TableRow = ({ children }) => {
	return <tr>{children}</tr>;
};

const TableHeading = ({ children }) => {
	return (
		<Typography className="font-semibold p-4" component="th" variant="body">
			{children}
		</Typography>
	);
};

const TableData = ({ children }) => {
	return (
		<Typography className="p-4" component="td" variant="body">
			{children}
		</Typography>
	);
};

Table.propTypes = {
	children: reactChildren.isRequired,
};

TableRow.propTypes = {
	children: reactChildren.isRequired,
};

TableHeading.propTypes = {
	children: reactChildren.isRequired,
};

TableData.propTypes = {
	children: reactChildren.isRequired,
};

export const table = Table;
export const tr = TableRow;
export const th = TableHeading;
export const td = TableData;
