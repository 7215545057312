import CheckboxGroup from '/components/CheckBoxGroup';
import Clickable from '/components/Clickable';
import InputGroup from '/components/InputGroup';
import Loading from '/components/Loading';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { subscribeToProductAlert } from '/services/back-in-stock';
import { trackBackInStock } from '/services/analytics/shinesty-analytics';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { validateEmail } from '/utils/common-utils';

import { useRef, useState } from 'react';

export default function BackInStock({ backInStockSettings }) {
	const inputRef = useRef(null);

	const [backInStockAlertMessage, setBackInStockAlertMessage] = useState();
	const [backInStockAlertType, setBackInStockAlertType] = useState();
	const [backInStockEmail, setBackInStockEmail] = useState('');
	const [backInStockSubcribe, setBackInStockSubcribe] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const { executeRecaptcha } = useGoogleReCaptcha();

	const submitBackInStockForm = async (event) => {
		event.preventDefault();

		if (isLoading) {
			return;
		}

		if (!validateEmail(backInStockEmail)) {
			setBackInStockAlertMessage('Please enter a valid email address and try again.');
			setBackInStockAlertType('Error');
			return;
		}

		setIsLoading(true);

		const response = await subscribeToProductAlert(
			backInStockSettings,
			backInStockEmail,
			backInStockSubcribe,
			executeRecaptcha,
			'backInStock',
		);

		if (response?.data?.status) {
			const responseMessage =
				response.data.errors?.message || response.data.errors?.base?.[0] || "We'll be in touch!";

			setBackInStockAlertMessage(responseMessage);
			setBackInStockAlertType(response.data.status);
			trackBackInStock(backInStockSettings);
		} else {
			setBackInStockAlertMessage('Unknown Error');
			setBackInStockAlertType('Error');
		}

		setIsLoading(false);
	};

	return (
		<div className="flex gap-2 flex-col my-4 text-center p-4 border-2 rounded-lg">
			<Typography component="h2" variant="heading-sm">
				He Gone. But will return!
			</Typography>
			{!backInStockAlertMessage && (
				<Typography variant="body" component="p" className="text-left">
					Jot your email below and we&apos;ll send you a 1-time email as soon as it&apos;s back.
				</Typography>
			)}

			{backInStockAlertMessage && (
				<Typography
					className={classNames(
						'py-2 w-full',
						backInStockAlertType === 'Error' ? 'italic text-primary' : '',
						backInStockAlertType === 'OK' ? 'bg-green-50' : '',
					)}
					component="div"
					variant="body">
					{backInStockAlertMessage}
				</Typography>
			)}

			<form
				className={classNames(
					'mt-2 flex-col flex gap-2 items-center',
					backInStockAlertType === 'OK' ? 'hidden' : 'visible',
				)}
				onSubmit={(e) => {
					submitBackInStockForm(e);
				}}>
				<div className="flex flex-col gap-1 items-center align-middle justify-center">
					<div className="flex">
						<InputGroup
							ref={inputRef}
							type="text"
							className="border rounded-l-full border-gray-500 mb-4 placeholder-gray-600 px-2 w-full"
							adaTitle="Email"
							placeholder="Email"
							value={backInStockEmail}
							onChange={(val) => {
								setBackInStockEmail(val);
							}}
						/>
						<Clickable
							className="rounded-r-full mb-4 bg-gray-600 !px-3"
							disabled={isLoading}
							heapEventName="Back In Stock Notification Signup"
							title="Tell Me When It's Back"
							buttonType="submit"
							variant="filled">
							Notify Me
						</Clickable>
					</div>
				</div>
				{isLoading && <Loading height="16px" width="16px" />}
				<CheckboxGroup
					checked={backInStockSubcribe}
					name="back-in-stock-marketing"
					label={
						<Typography className="text-left" component="p" variant="body">
							Also send me your award winning emails about other wearable aphrodisiacs.
						</Typography>
					}
					onChange={(checked) => setBackInStockSubcribe(checked)}
				/>
			</form>
		</div>
	);
}

BackInStock.propTypes = {
	backInStockSettings: PropTypes.object,
};
