import Image from '/components/Image';
import PropTypes from 'prop-types';
import ReviewStars from '/components/ReviewStars';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { parseGid } from '/utils/graph-ql';

const ProductTitle = ({ product, align = 'center' }) => {
	const productId = parseGid(product.id);

	let extraTitle;
	if (product.titles.length > 2) {
		extraTitle = product.titles.reduce((memo, title, i) => {
			if (i > 1) {
				memo.push(title.trim());
			}
			return memo;
		}, []);
	}

	if (product.productType == 'Laundry Detergent Sheets') {
		return (
			<div className="container px-1 md:px-0 flex items-center align-middle flex-col md:block">
				<Image
					src="https://cdn.shinesty.com/2023-09-06/full-of-sheet-logo.svg"
					width={350}
					height={200}
					objectFit="contain"
					alt="clouds"
				/>
				{product.titles[1] && (
					<Typography className="mt-2" component="p" variant="body">
						{product.titles[1].trim()}
					</Typography>
				)}
				{extraTitle && (
					<Typography className="italic text-primary" component="div" variant="body">
						{extraTitle.join(' ')}
					</Typography>
				)}
				<div className="lg:mb-2 flex justify-center md:justify-start mt-1">
					<a href="#reviews-section" className="flex justify-center">
						<ReviewStars productId={productId} />
					</a>
				</div>
			</div>
		);
	}

	return (
		<div className="container px-1 md:px-0">
			{product.titles[0] && (
				<Typography className="grid" component="h1" variant="subtitle-lg">
					{product.titles[0].trim()}
					{product.titles[1] && (
						<span className="text-sm leading-[1.40rem] tracking-wider">
							{product.titles[1].trim()}
						</span>
					)}
				</Typography>
			)}

			{extraTitle && (
				<Typography className="italic text-primary" component="div" variant="body">
					{extraTitle.join(' ')}
				</Typography>
			)}
			<div
				className={classNames(
					'lg:mb-2 flex md:justify-start mt-2',
					align == 'start' ? 'justify-start' : 'justify-center',
				)}>
				<a href="#reviews-section" className="flex justify-center">
					<ReviewStars productId={productId} />
				</a>
			</div>
		</div>
	);
};

ProductTitle.propTypes = {
	align: PropTypes.string,
	product: PropTypes.object,
};

export default ProductTitle;
