import Typography from '/components/Typography';
import { reactChildren } from '/utils/prop-types';

const ListItem = ({ children }) => {
	return (
		<Typography
			className="pb-2"
			component="li"
			variant="body"
			style={{
				marginLeft: '20px',
				textIndent: '-20px',
				paddingLeft: '20px',
			}}>
			{children}
		</Typography>
	);
};

const OrderedList = ({ children }) => {
	return (
		<ol
			className="list-decimal"
			style={{
				listStylePosition: 'inside',
				paddingLeft: '0',
			}}>
			{children}
		</ol>
	);
};

const UnorderedList = ({ children }) => {
	return (
		<ul
			className="list-disc list-inside"
			style={{
				listStylePosition: 'inside',
				paddingLeft: '0',
			}}>
			{children}
		</ul>
	);
};

ListItem.propTypes = {
	children: reactChildren.isRequired,
};

OrderedList.propTypes = {
	children: reactChildren.isRequired,
};

UnorderedList.propTypes = {
	children: reactChildren.isRequired,
};

export const li = ListItem;
export const ol = OrderedList;
export const ul = UnorderedList;
