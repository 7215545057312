import Typography from '/components/Typography';

import { reactChildren } from '/utils/prop-types';

const Strong = ({ children }) => {
	return (
		<Typography className="mb-4 font-bold" variant="body">
			{children}
		</Typography>
	);
};

Strong.propTypes = {
	children: reactChildren.isRequired,
};

export const strong = Strong;
