import ProductOptions from '/components/products/show/product-form/ProductOptions';
import PropTypes from 'prop-types';

export default function ProductOptionsPacks({
	product,
	selectedOptions,
	setBackInStockSettings,
	setSelectedOptions,
}) {
	return (
		<ProductOptions
			isPackedProduct={true}
			primary={true}
			product={product}
			selectedOptions={selectedOptions}
			setBackInStockSettings={setBackInStockSettings}
			setSelectedOptions={setSelectedOptions}
			showFromPrice={false}
			showNoneOption={false}
			showProductType={false}
		/>
	);
}

ProductOptionsPacks.propTypes = {
	product: PropTypes.object.isRequired,
	selectedOptions: PropTypes.array.isRequired,
	setBackInStockSettings: PropTypes.func.isRequired,
	setSelectedOptions: PropTypes.func.isRequired,
};
