import Button from '/components/Button';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { useState } from 'react';

const IndentedList = ({ detail }) => {
	return (
		<ul className="list-disc pl-8">
			{detail.map((text, i) => {
				return (
					<li key={i}>
						<Typography variant="body">{text}</Typography>
					</li>
				);
			})}
		</ul>
	);
};

const ReadMoreList = ({ contents }) => {
	const [expanded, setExpanded] = useState(false);
	const [itemsToShow, setItemsToShow] = useState(3);

	const showMore = () => {
		itemsToShow === 3
			? (setExpanded(true), setItemsToShow(contents.length))
			: (setExpanded(false), setItemsToShow(3));
	};

	return (
		<div>
			<ul className="list-disc pl-4">
				{contents.slice(0, itemsToShow).map((detail, i) => {
					if (Array.isArray(detail)) {
						return <IndentedList detail={detail} />;
					}

					return (
						<li key={i}>
							<Typography variant="body">{detail}</Typography>
						</li>
					);
				})}
			</ul>
			{contents.length > 3 ? (
				<Button
					className="ml-1 "
					onClick={() => {
						showMore();
					}}
					variant="link">
					{expanded ? 'Show Less' : 'Read More'}
				</Button>
			) : (
				''
			)}
		</div>
	);
};

ReadMoreList.propTypes = {
	contents: PropTypes.array,
};

IndentedList.propTypes = {
	detail: PropTypes.array,
};

export default ReadMoreList;
