import Clickable from '/components/Clickable';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import { reactChildren } from '/utils/prop-types';

const Anchor = ({ children, href }) => {
	return (
		<Clickable linkToExternal={href}>
			<Typography className="text-primary" variant="body">
				{children}
			</Typography>
		</Clickable>
	);
};

Anchor.propTypes = {
	children: reactChildren.isRequired,
	href: PropTypes.string,
};

export const a = Anchor;
