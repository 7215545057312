import { logTryCatch } from '/utils/logging';
import { parseGid } from '/utils/graph-ql';
import {
	FROM_MIXED_PACK_PRODUCT,
	FROM_MIXED_PACK_PRODUCT_ID,
	FROM_MIXED_PACK_TITLE,
	FROM_PACK_PRODUCT,
	FROM_PACK_PRODUCT_TITLE,
	FROM_PACK_SKU,
	PACK_PRODUCT,
	SINGLE_ITEM_PRICE,
} from '/services/static/attribute-keys';

/**
 * @param {object} product the product (which includes options)
 * @returns a hash of variantId: variant name (typically, size)
 */
const generateVariantDataLookup = (product, joinedProducts, packProducts, quantity) => {
	const lookup = {};

	product.variants.forEach((variant) => {
		if (variant.selectedOptions) {
			lookup[variant.id] = {
				name: variant.selectedOptions[0].value,
				quantity: quantity || 1,
				sku: variant.sku,
				product: product,
			};
		}
	});

	joinedProducts.forEach((joinedProduct) => {
		joinedProduct.variants.forEach((variant) => {
			if (variant.selectedOptions) {
				lookup[variant.id] = {
					name: variant.selectedOptions[0].value,
					quantity: quantity || 1,
					sku: variant.sku,
					product: joinedProduct,
				};
			}
		});
	});

	// if the product has pack products, get their ids/quantities in a dictionary
	const isMixedPack = product?.productType === 'Mixed Packs';

	const productQuantities = product.tags.reduce(
		(memo, tag) => {
			if (tag.indexOf('packproduct') === -1) {
				return memo;
			}

			// split[0] = packproduct
			// split[1] = id
			// split[2] = quantity
			const split = tag.split(':');

			let productId;

			if (typeof split[1] === 'string') {
				productId = split[1].trim();
			}

			if (!productId) {
				return memo;
			}

			// underwear pack
			if (!isMixedPack && productId) {
				memo.byId[productId] = 1;

				try {
					const quantity = parseInt(split[2]);

					if (Number.isFinite(quantity)) {
						memo.byId[productId] = quantity;
						memo.total += quantity;
					} else {
						memo.total++;
					}
				} catch (e) {
					logTryCatch('cant parse joined');
				}

				return memo;
			}

			// split[0] = packproduct
			// split[1] = id
			// split[2] = position
			// split[3] = quantity
			// packproduct: handle: position: quantity

			if (isMixedPack && split[1]) {
				memo.byId[productId] = 1;

				try {
					const quantity = parseInt(split[3]);

					if (Number.isFinite(quantity)) {
						memo.byId[productId] = quantity;
						memo.total += quantity;
					} else {
						memo.total++;
					}
				} catch (e) {
					logTryCatch('cant parse joined');
				}
				return memo;
			}

			return memo;
		},
		{ byId: {}, total: 0 },
	);

	packProducts.forEach((packProduct) => {
		const packProductId = parseGid(packProduct.id);
		const quantity = productQuantities.byId[packProductId] || 1;
		const singleItemPrice = product.prices[0] / productQuantities.total;

		packProduct.variants.forEach((variant) => {
			if (variant.selectedOptions) {
				lookup[variant.id] = {
					name: variant.selectedOptions[0].value,
					quantity: quantity,
					product: packProduct,
					sku: variant.sku,
					attributes: [
						{ key: SINGLE_ITEM_PRICE, value: singleItemPrice.toString() },
						{
							key: isMixedPack ? FROM_MIXED_PACK_PRODUCT_ID : FROM_PACK_PRODUCT,
							value: parseGid(product.id),
						},
						{
							key: isMixedPack ? FROM_MIXED_PACK_TITLE : FROM_PACK_PRODUCT_TITLE,
							value: product.title,
						},
						{ key: FROM_PACK_SKU, value: product?.variants[0].sku },
						{ key: isMixedPack ? FROM_MIXED_PACK_PRODUCT : PACK_PRODUCT, value: 'true' },
					],
				};
			}
		});
	});

	return lookup;
};

module.exports = {
	generateVariantDataLookup,
};
