import axios from 'axios';
import { parseGid } from '/utils/graph-ql';
import { validateEmail } from '/utils/common-utils';

const subscribeToProductAlert = async (
	params,
	email,
	subscribe,
	executeRecaptcha,
	recaptchaKey,
) => {
	if (!validateEmail(email)) {
		return {
			message: 'Please check that the email you entered is not missing characters and try again.',
			status: 'error',
		};
	}

	const rightNow = new Date();

	// recaptcha token gets fetched client side and validated serverside
	const recaptchaResponse = await executeRecaptcha(recaptchaKey);

	if (!recaptchaResponse) {
		return {
			message: 'Oops. Invalid captcha. Please try again.',
			status: 'error',
		};
	}

	await axios({
		data: {
			email,
			marketingConsent: subscribe,
			recaptchaResponse,
			shopifyProductId: params.productId,
			shopifyVariantId: params.variantId,
		},
		method: 'POST',
		url: `${process.env.NEXT_PUBLIC_SHINE_API}/public/back-in-stock`,
	})
		.then(() => {
			console.log('Shine-platform OK');
		})
		.catch(() => {
			console.log('Error sending back in stock to shine-platform');
		});

	return axios({
		method: 'get',
		url: `https://app.backinstock.org/stock_notification/create.json`,
		params: {
			shop: process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN,
			'notification[accepts_marketing]': subscribe,
			'notification[customer_utc_offset]': rightNow.getTimezoneOffset() * 60,
			'notification[email]': email,
			'notification[product_no]': parseGid(params.productId),
			'notification[quantity_required]': 1,
			'variant[variant_no]': parseGid(params.variantId),
		},
	});
};

module.exports = {
	subscribeToProductAlert,
};
