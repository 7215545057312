import Clickable from '/components/Clickable';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';

export default function ImageButton({
	disabled = false,
	onClick,
	overlay,
	reverseStyle,
	selected,
	showOverlay,
	styleObj,
}) {
	const { handle, image, position, title } = styleObj;
	const buttonImageSrc = styleObj?.alternateProductImages?.buttonSrc || styleObj.buttonImageSrc;

	return (
		<Clickable
			className={classNames(
				' outline-2  outline-offset-2 flex items-center justify-center overflow-hidden relative rounded-full m-[5px] h-12 w-12 hover:ring-gray-400 hover:border-2 border-white hover:ring-4',
				selected
					? reverseStyle
						? 'border-2 border-white ring-4 !ring-gray-600 rounded-full hover:ring-gray-600'
						: 'ring-4 ring-gray-600 rounded-full'
					: '',
			)}
			disabled={disabled}
			heapEventData={{
				Handle: handle,
				position: position,
			}}
			heapEventName="Couples Subscription Thumbnail Click"
			onClick={onClick}
			title={`select ${title}`}>
			<Image
				alt={title}
				className="flex-grow"
				height={50}
				objectFit="contain"
				src={buttonImageSrc || image}
				width={50}
			/>
			{showOverlay && overlay !== false && (
				<Typography className="absolute font-semibold text-white z-20 w-12 h-12 bg-black bg-opacity-40 pt-4">
					{overlay}
				</Typography>
			)}
		</Clickable>
	);
}

ImageButton.defaultProps = {
	reverseStyle: false,
};

ImageButton.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	overlay: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	reverseStyle: PropTypes.bool,
	selected: PropTypes.bool.isRequired,
	showOverlay: PropTypes.bool.isRequired,
	styleObj: PropTypes.object.isRequired,
};
