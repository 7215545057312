const formatProduct = (product) => {
	if (product.productId) {
		product.shopifyProductId = product.productId;
	}

	if (product.variantId) {
		product.shopifyVariantId = product.variantId;
	}
	return product;
};

module.exports = {
	formatProduct,
};
