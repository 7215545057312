import { firstElementProperty } from '/utils/common-utils';
import { linesFromVariants } from '/utils/product';
import { parseGid } from '/utils/graph-ql';
import { v4 as uuidv4 } from 'uuid';
import {
	ADD_TO_CART_ID,
	AUTO_REMOVE,
	BUILD_A_PACK,
	FROM_PACK_PRODUCT_ID,
	FROM_PACK_SKU,
	FROM_WOMENS_IMAGE,
	FROM_WOMENS_LISTING,
	FROM_WOMENS_TITLE,
	IS_DISCOUNTED_COOLING,
	PACK_ID,
	PACK_PRODUCT,
	PREBUILT_PACK_TYPE,
	PRODUCT_TYPE,
	SITEWIDE_PACK_BUILDER,
} from '/services/static/attribute-keys';

const getAddToCartDataStandard = (
	freeProduct,
	hasSwappedVariants,
	isMixedPack,
	isUnderwearPack,
	packProducts,
	preBuiltPackType,
	product,
	selectedOptions,
	variantDataLookup,
	variantIds,
	vueInfo,
	sitewidePack,
) => {
	let variantLineData;
	const addToCartId = uuidv4();
	const attributeOverrides = [];

	if (product.productType.includes('Cooling')) {
		if (Number(product.prices[0]) < Number(product.compareAtPrices[0])) {
			attributeOverrides.push({ key: IS_DISCOUNTED_COOLING, value: 'true' });
		}
	}

	if (sitewidePack) {
		attributeOverrides.push({ key: SITEWIDE_PACK_BUILDER, value: 'true' });
		attributeOverrides.push({ key: PRODUCT_TYPE, value: product.productType });
		attributeOverrides.push({ key: BUILD_A_PACK, value: 'true' });
	}

	if ((isUnderwearPack || preBuiltPackType) && selectedOptions.length === 1) {
		const size = selectedOptions[0].variantTitle;

		const packId = new Date().getTime().toString();

		const defaultAttributes = [
			{ key: ADD_TO_CART_ID, value: addToCartId },
			{ key: PACK_ID, value: packId },
		];

		if (preBuiltPackType) {
			defaultAttributes.push({ key: PREBUILT_PACK_TYPE, value: preBuiltPackType });
		} else {
			defaultAttributes.push({ key: PACK_PRODUCT, value: 'true' });
		}

		if (product) {
			defaultAttributes.push({ key: FROM_PACK_PRODUCT_ID, value: parseGid(product.id) });
		}

		if (product?.variants) {
			defaultAttributes.push({ key: FROM_PACK_SKU, value: product.variants[0].sku });
		}

		variantLineData = packProducts.map((packProduct) => {
			const variant =
				packProduct.variants &&
				packProduct.variants.find((packProductVariant) => {
					return packProductVariant.title === size;
				});

			if (!variant) {
				return;
			}

			const attributes = variantDataLookup[variant.id]
				? [...defaultAttributes, ...variantDataLookup[variant.id].attributes, ...attributeOverrides]
				: [...defaultAttributes, ...attributeOverrides];

			return {
				attributes: attributes,
				price: packProduct.prices[0],
				product: packProduct,
				quantity: variantDataLookup[variant.id]?.quantity || 1,
				variantId: variant.id,
				variantSku: variantDataLookup[variant.id]?.sku || '',
				variantTitle: variantDataLookup[variant.id]?.name || '',
			};
		});
	} else {
		variantLineData = variantIds.map((id) => {
			return {
				attributes: [
					{ key: ADD_TO_CART_ID, value: addToCartId },
					...(variantDataLookup[id]?.attributes || []),
					...attributeOverrides,
				],
				price: variantDataLookup[id]?.product?.prices[0] || '',
				product: variantDataLookup[id]?.product,
				quantity: variantDataLookup[id]?.quantity || 1,
				variantId: id,
				variantSku: variantDataLookup[id]?.sku || '',
				variantTitle: variantDataLookup[id]?.name || '',
				vueInfo: vueInfo || '',
				//module id etc
			};
		});
	}

	if (hasSwappedVariants === true) {
		variantLineData.forEach((lineData) => {
			if (!lineData.attributes) {
				lineData.attributes = [{ key: ADD_TO_CART_ID, value: addToCartId }];
			}

			lineData.attributes.push({
				key: FROM_WOMENS_IMAGE,
				value: firstElementProperty(product.images, 'src'),
			});
			lineData.attributes.push({ key: FROM_WOMENS_LISTING, value: 'true' });
			lineData.attributes.push({ key: FROM_WOMENS_TITLE, value: product.title });
		});
	}

	if (freeProduct && freeProduct.variants && freeProduct.variants.length === 1) {
		variantLineData.push({
			attributes: [
				{ key: ADD_TO_CART_ID, value: addToCartId },
				{ key: AUTO_REMOVE, value: 'true' },
			],
			variantId: freeProduct.variants[0].id,
			quantity: 1,
		});
	}

	const lines = linesFromVariants(variantLineData);

	return {
		analytics: variantLineData,
		analyticsProduct: isUnderwearPack || preBuiltPackType || isMixedPack ? product : null,
		lines,
	};
};

module.exports = {
	getAddToCartDataStandard,
};
