import Clickable from '/components/Clickable';
import Loading from '/components/Loading';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';

const AddToCart = ({
	addItemsToCart,
	addToCartLoading,
	alternateBackground,
	cartType,
	location,
	product,
	selectedOptions,
	showAddToCartError = false,
	showNotSatisfiedError = false,
}) => {
	let addToCartClass = 'flex items-center justify-center w-full';

	if (alternateBackground) {
		addToCartClass += ` ${alternateBackground}`;
	}

	return (
		<div className="mb-4">
			<Clickable
				id="add_to_cart"
				className={addToCartClass}
				heapEventName="Add to Cart"
				heapEventData={{
					Source: location,
					Product: product.handle,
					ProductType: product.productType,
					'Purchase Type': cartType,
					Price: product.prices[0],
					objectId: selectedOptions[0]?.variantId,
				}}
				title="add product to cart"
				onClick={addItemsToCart}
				disabled={selectedOptions.length < 1 || showNotSatisfiedError}
				variant="filled">
				<Typography component="p" variant="body">
					Add to Cart
				</Typography>
				{addToCartLoading && <Loading className="ml-2" />}
			</Clickable>

			{showNotSatisfiedError && (
				<div className="p-2 w-full text-center">
					<Typography className="uppercase text-primary text-center">
						Select an Option to Add to Cart
					</Typography>
				</div>
			)}

			{showAddToCartError && (
				<div className="border border-primary p-2 w-full">
					<Typography className="font-bold uppercase text-primary">
						Whoops! It&apos;s Us Not You
					</Typography>
					<div>
						<Typography variant="body">
							We weren&apos;t able to add this item to cart, please try again. If this persists,
							please{' '}
						</Typography>
						<Clickable
							heapEventName="Cart Error: Contact Us"
							linkToExternal="mailto:customerservice@shinesty.com">
							<Typography variant="body" className="mb-1 hover:text-primary underline">
								contact us.
							</Typography>
						</Clickable>
					</div>
				</div>
			)}
		</div>
	);
};

AddToCart.propTypes = {
	addItemsToCart: PropTypes.func.isRequired,
	addToCartLoading: PropTypes.bool,
	alternateBackground: PropTypes.string,
	cartType: PropTypes.string,
	location: PropTypes.string,
	product: PropTypes.object.isRequired,
	selectedOptions: PropTypes.array,
	showAddToCartError: PropTypes.bool,
	showNotSatisfiedError: PropTypes.bool,
};

export default AddToCart;
