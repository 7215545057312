import Countdown from '/components/Countdown';
import PropTypes from 'prop-types';
import Typography from '@/components/Typography';

const ProductFormCountdown = ({ promotion }) => {
	if (!promotion || promotion == {} || promotion.promotionType !== 'money-back') {
		return null;
	}

	return (
		<div className="border-2 border-secondary-darker bg-secondary/10 p-3 rounded-lg max-w-sm mx-auto my-4 text-center">
			<Typography variant="subtitle-xs" className="font-semibold text-secondary-darker grid">
				🕒 Act Fast: 30% Money Back Ends In:
				<span className="font-bold">
					<Countdown countdownDate={promotion.dateEnd} />
				</span>
			</Typography>
		</div>
	);
};

ProductFormCountdown.propTypes = {
	promotion: PropTypes.object,
};

export default ProductFormCountdown;
