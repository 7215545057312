import Image from '/components/Image';
import Typography from '/components/Typography';

const FreeKoozieMessaging = () => {
	return (
		<div className="w-full border-primary border-2 rounded-md overflow-hidden grid grid-cols-2">
			<Image
				src={'https://cdn.shinesty.com/2024-10-23/kooziepicback.png'}
				alt="Photo of hand holding a koozie"
				className="object-cover w-full h-full absolute inset-0"
				width={400}
				height={500}
			/>

			<div className="flex items-center justify-center p-4">
				<Typography variant="heading-sm" className="capitalize text-center">
					Free coozie with all <br></br>&apos;I Voted&apos;<br></br> underwear purchases
				</Typography>
			</div>
		</div>
	);
};

export default FreeKoozieMessaging;
