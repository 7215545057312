import { checkFunctionality } from '/services/static/countries';
import { checkProductTags } from '/utils/product-tags';

// used for products where we want to offer a subscription on the page but don't offer a subscription for that product type.
const subscriptionProductTypeLookup = {
	'Thongs - Cooling': 'Thongs',
	'Boxers - Cooling - Fly': 'Boxers - Fly',
};

const checkAvailableSubscription = (product, countryCode) => {
	const countrySubscriptionIsAvailable = checkFunctionality(countryCode, 'subscription');

	if (!countrySubscriptionIsAvailable || !product || !product.productType) {
		return false;
	}

	if (product.productType.includes('Cooling')) {
		if (Number(product.prices[0]) < Number(product.compareAtPrices[0])) {
			return false;
		}
	}

	const tagCheck = checkProductTags(product.tags, 'Show Subscribe Option');
	const alternateCheck = findAlternateSubscriptionProductType(product.productType);

	return (tagCheck || alternateCheck) && product.availableForSale;
};

const findAlternateSubscriptionProductType = (productType) => {
	return productType && subscriptionProductTypeLookup[productType];
};

module.exports = {
	checkAvailableSubscription,
	findAlternateSubscriptionProductType,
};
