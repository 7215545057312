const generateProductOptionSettings = (joinedProducts, packProducts) => {
	const hasJoinedProducts = joinedProducts?.length > 0 || false;
	const hasPackedProducts = packProducts?.length > 0 || false;

	if (!hasPackedProducts) {
		return {
			hasJoinedProducts,
			hasPackedProducts,
		};
	}

	// this gets rather confusing... pack products can have:
	// - one product handle (5 pack of threat level midnight)
	// - multiple product handles (big bang thong + big bang boxer)
	// - one product type (boxer 3 pack with 3 different prints)
	const productInfo = packProducts.reduce(
		(memo, product) => {
			if (!memo.handles[product.handle]) {
				memo.handles[product.handle] = 0;
			}

			if (!memo.productTypes[product.productType]) {
				memo.productTypes[product.productType] = 0;
			}

			memo.handles[product.handle]++;
			memo.productTypes[product.productType]++;
			return memo;
		},
		{
			handles: {},
			productTypes: {},
		},
	);

	return {
		hasJoinedProducts,
		hasPackedProducts,
		numberOfPackedProducts: Object.keys(productInfo.handles).length,
		numberOfPackedProductTypes: Object.keys(productInfo.productTypes).length,
	};
};

module.exports = {
	generateProductOptionSettings,
};
