import ProductOptions from '/components/products/show/product-form/ProductOptions';
import PropTypes from 'prop-types';

export default function ProductOptionsPackedStandard({
	packProducts = [],
	selectedOptions,
	setBackInStockSettings,
	setSelectedOptions,
	showProductType,
}) {
	return (
		<>
			{packProducts.map((jp, i) => {
				return (
					<ProductOptions
						isPackedProduct={true}
						key={i}
						packProducts={packProducts}
						position={i}
						primary={false}
						product={jp}
						selectedOptions={selectedOptions}
						setBackInStockSettings={setBackInStockSettings}
						setSelectedOptions={setSelectedOptions}
						showFromPrice={false}
						showNoneOption={false}
						showProductType={showProductType}
					/>
				);
			})}
		</>
	);
}

ProductOptionsPackedStandard.propTypes = {
	packProducts: PropTypes.array,
	selectedOptions: PropTypes.array.isRequired,
	setBackInStockSettings: PropTypes.func.isRequired,
	setSelectedOptions: PropTypes.func.isRequired,
	showProductType: PropTypes.bool,
};
