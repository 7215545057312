const taggedData = {
	'Final Sale': ['Final Sale, No Returns'],
	'Forever Collectibles Licensed': [
		'Officially licensed by the NFL (National Football League)',
		'Manufactured by Forever Collectibles',
	],
	Glows: ['Glow in the Dark!! Expose to bright light for 30+ minutes for best effect'],
	'Heat Activated': ['To activate color change: apply hot air/breath to garment'],
	'Little Earth Licensed': ['Officially licensed and manufactured by Little Earth'],
	'Not Glitter': ['NOT made of actual glitter or sequins. Printed polyester to look like it.'],
	'Officially Licensed': ['Officially Licensed'],
};

const discoverData = {
	USA: [
		{
			text: 'USA Themed Clothing',
			link: '/collections/usa-themed-clothing',
		},
	],
	Blazers: [
		{
			text: "Men's Party Suits",
			link: '/collections/party-suits',
		},
		{
			text: 'Party Pants',
			link: '/collections/mens-pants',
		},
	],
	Sunglasses: [
		{
			text: "Men's Party Suits",
			link: '/collections/party-suits',
		},
		{
			text: 'Party Pants',
			link: '/collections/mens-pants',
		},
	],
};

const getTaggedDetails = (tagged) => {
	return taggedData[tagged];
};

const getTaggedDiscover = (themes, types) => {
	const foundTheme = Object.keys(discoverData).find((data) => {
		return themes.find((theme) => {
			return data == theme;
		});
	});
	const foundType = Object.keys(discoverData).find((data) => {
		return types.find((type) => {
			return data == type;
		});
	});
	if (foundTheme) {
		return discoverData[foundTheme];
	}
	if (foundType) {
		return discoverData[foundType];
	}
};

module.exports = {
	getTaggedDetails,
	getTaggedDiscover,
};
