import ImageButton from '/components/products/subscription/ImageButton';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getButtonImageSrc } from '/services/products';
import { useEffect } from 'react';

export default function SubscriptionDesigns({
	availableProducts,
	customerSelections,
	showOverlay = false,
	showSelectedItemTitle = true,
	title,
	textColor,
	toggleProduct,
}) {
	const { activeProduct, selectedFlavor, selectedProducts, selectedSize } = customerSelections;

	useEffect(() => {
		if (selectedProducts.length === 0 && availableProducts.length > 0) {
			toggleProduct(availableProducts[0]);
		}
	}, [availableProducts]);

	return (
		<main className={selectedSize && selectedFlavor ? 'visible' : 'hidden'}>
			{title && (
				<Typography
					className={classNames(
						'mt-4 font-bold tracking-widest uppercase',
						textColor === 'black' ? 'text-black' : 'text-white',
					)}
					component="div"
					variant="body">
					{title}{' '}
					{showSelectedItemTitle && activeProduct && (
						<span className="font-body capitalize">{activeProduct.title}</span>
					)}
				</Typography>
			)}
			<div className="flex flex-wrap justify-center my-4">
				{availableProducts.map((product) => {
					const selectedIndex = selectedProducts.findIndex((selectedProduct) => {
						return selectedProduct?.handle === product.handle;
					});

					let buttonImageSrc = getButtonImageSrc(product);

					return (
						<ImageButton
							key={product.handle}
							onClick={() => toggleProduct(product)}
							overlay={selectedIndex === -1 ? undefined : selectedIndex + 1}
							reverseStyle={textColor === 'black'}
							showOverlay={showOverlay}
							selected={selectedIndex > -1}
							styleObj={{ ...product, buttonImageSrc: buttonImageSrc }}
						/>
					);
				})}
			</div>
		</main>
	);
}

SubscriptionDesigns.propTypes = {
	availableProducts: PropTypes.array.isRequired,
	customerSelections: PropTypes.object.isRequired,
	showOverlay: PropTypes.bool,
	showSelectedItemTitle: PropTypes.bool,
	textColor: PropTypes.string,
	title: PropTypes.string,
	toggleProduct: PropTypes.func.isRequired,
};
