import PropTypes from 'prop-types';
import React from 'react';
import Typography from '/components/Typography';
import { reactChildren } from '/utils/prop-types';

const Heading = ({ children, level }) => {
	const component = `h${Math.min(level, 6)}`;

	// this maps a component (h1,h2,h3) to the appropriate variant (in typography)
	const variantLookup = {
		h1: 'heading-lg',
		h2: 'subhead-lg',
		h3: 'subtitle-lg',
		h4: 'heading-sm',
		h5: 'subhead-sm',
		h6: 'subtitle-md',
	};

	return (
		<Typography className="mb-4" component={component} variant={variantLookup[component] || ''}>
			{children}
		</Typography>
	);
};

Heading.propTypes = {
	children: reactChildren.isRequired,
	level: PropTypes.number,
};

export const h1 = Heading;
export const h2 = Heading;
export const h3 = Heading;
export const h4 = Heading;
export const h5 = Heading;
export const h6 = Heading;
