import { findProductTag } from '/utils/product-tags';
import { getFirstProperty } from '/utils/array';
import { getSubscriptionData } from '/services/static/subscription/subscriptions-ordergroove';
import { linesFromVariants } from '/utils/product';
import { titleCase } from '/utils/format';
import { toTitleCase } from '/utils/common-utils';
import {
	FIRST_PAIR_TITLE,
	FLAVOR,
	IS_SUBSCRIPTION,
	SUBSCRIPTION_PRICE,
	SUBSCRIPTION_TITLE,
} from '/services/static/attribute-keys';

const getAddToCartDataForSubscription = (
	product,
	subscriptionAlternateProduct,
	variantDataLookup,
	variantId,
	vueInfo,
	sellingPlan,
	discountCodeStore,
) => {
	const variantData = variantDataLookup[variantId];

	if (!variantData) {
		console.log('could not find variant data');
	}

	const sizeOption = variantData.name;
	const sku = variantData.sku;

	const attributes = [];

	const productFlavor = findProductTag(product.tags, 'flavor', 1, 'surprise');

	if (productFlavor) {
		attributes.push({ key: FLAVOR, value: titleCase(productFlavor) });
	}

	attributes.push({ key: SUBSCRIPTION_PRICE, value: sellingPlan.recurringPrice });
	attributes.push({ key: SUBSCRIPTION_TITLE, value: sellingPlan.cartText });
	attributes.push({ key: FIRST_PAIR_TITLE, value: product.title });
	attributes.push({ key: IS_SUBSCRIPTION, value: 'true' });

	let lines = [];

	let subscriptionDataSettings = {
		attributes,
		discountCodeStore,
		frequencyKey: sellingPlan.frequency,
		selections: [
			{
				image: product.images?.length > 0 && product.images[0].src,
				productType: product.productType,
				size: sizeOption,
				title: product.title,
				variantId: variantId,
				variantSku: sku,
				product_id: product.id,
				sellingPlanId: sellingPlan.id,
				price: sellingPlan.recurringPrice,
			},
		],
		sellingPlanId: sellingPlan.id,
		subscriptionType: 'single',
		vueInfo,
	};

	if (subscriptionAlternateProduct) {
		const selectedVariant = subscriptionAlternateProduct.variants.find(
			(variant) => variant.title == sizeOption,
		);

		subscriptionDataSettings = {
			attributes,
			discountCodeStore,
			frequencyKey: 'monthly',
			selections: [
				{
					image: getFirstProperty(subscriptionAlternateProduct, 'images', 'imageSrc'),
					productType: toTitleCase(subscriptionAlternateProduct.shopifyProductType),
					product_id: subscriptionAlternateProduct.shopifyProductId,
					size: sizeOption,
					title: subscriptionAlternateProduct.title,
					variantId: selectedVariant.shopifyVariantId,
					variantSku: selectedVariant.sku,
				},
			],
			subscriptionType: 'single',
			sellingPlanId: sellingPlan.id,
			vueInfo,
		};

		const originalProductLines = linesFromVariants([
			{
				attributes: variantData?.attributes,
				price: product.prices[0],
				product: product,
				quantity: variantData?.quantity || 1,
				variantId: variantId,
				variantSku: variantData?.sku || '',
				variantTitle: variantData?.name || '',
			},
		]);

		lines = [...lines, ...originalProductLines];
	}

	const subscriptionData = getSubscriptionData(subscriptionDataSettings);

	// you need to add the original product back into the analytics payload since
	// the user is adding a subscription AND a second product.
	if (subscriptionAlternateProduct) {
		subscriptionData.analytics.push({
			attributes: variantData?.attributes || [],
			price: variantData?.product?.prices[0] || '', // Not sure if we need to pass full price or discounted price?
			product: variantData?.product,
			quantity: variantData?.quantity || 1,
			variantId: variantId,
			variantSku: variantData?.sku || '',
			variantTitle: variantData?.name || '',
		});
	}

	lines = [...lines, ...subscriptionData.lineItems];

	return {
		analytics: subscriptionData.analytics,
		lines,
	};
};

module.exports = {
	getAddToCartDataForSubscription,
};
