import Button from '/components/Button';
import Icon from '/components/IconUpdated';
import Image from '/components/Image';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import dynamic from 'next/dynamic';

const Modal = dynamic(() => import('/components/Modal'));
const ModalBody = dynamic(() => import('/components/Modal').then((mod) => mod.ModalBody));

export default function ModalAfterpay({ modalShopPayOpen, setModalShopPayOpen }) {
	return (
		<Modal
			id="afterpay"
			isOpen={modalShopPayOpen}
			size="large"
			toggle={(val) => setModalShopPayOpen(val)}>
			<ModalBody>
				<button
					aria-label="Close"
					className="absolute top-0 right-0 p-4"
					data-dismiss="modal"
					onClick={() => setModalShopPayOpen(!modalShopPayOpen)}
					type="button">
					<Icon name="close" height="18px" width="18px" />
				</button>
				<div className="p-6 bg-[#B2FCDE] text-center md:h-[600px] grid">
					<div className="flex justify-center">
						<Image
							src="https://cdn.shinesty.com/2024-05-24/AP_logo_lockup_6328x2204_blackclear_png.png"
							height={70}
							width={200}
							alt="afterpay logo"
							objectFit="contain"
						/>
					</div>
					<Typography component="h1" variant="heading-md">
						Shop now. Pay in 4. Always interest-free.
					</Typography>
					<div className="grid grid-cols-2 md:grid-cols-4 gap-4 pt-4">
						<div className="flex flex-col items-center space-y-2 justify-between">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								role="img"
								viewBox="0 0 52 72"
								className="max-h-[100px]"
								aria-label="Step 1">
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M26 55.47a27.28 27.28 0 01-4-3c-2.13-1.87-4.52-4.49-4.44-7.36a5 5 0 01.37-1.75 4.27 4.27 0 018.13.25 4.27 4.27 0 018.13-.25 5 5 0 01.37 1.75c.08 2.87-2.31 5.49-4.44 7.36a27.28 27.28 0 01-4 3"></path>
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M10.5 35V17a15.5 15.5 0 0131 0v18"></path>
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									transform="rotate(90 85.5 -4.5)"
									d="M110.5 30.5h45.79a4.21 4.21 0 014.21 4.21v40.58a4.21 4.21 0 01-4.21 4.21H110.5v-49z"></path>
							</svg>
							<Typography>Add your favorites to cart</Typography>
						</div>

						<div className="flex flex-col items-center space-y-2 justify-between">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								role="img"
								viewBox="0 0 82 56"
								className="max-h-[100px]"
								aria-label="Step 2">
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M36.5 52.49h9"></path>
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M10.43 1.5h61.14a3.93 3.93 0 013.93 3.93V50.5h-69V5.43a3.93 3.93 0 013.93-3.93z"></path>
								<path d="M54.75 17.52L50.3 15l-4.51-2.59a4.48 4.48 0 00-6.71 3.89v.58a.89.89 0 00.44.77l2.1 1.2a.86.86 0 001.3-.75v-1.41a.89.89 0 011.33-.77l4.12 2.37 4.11 2.36a.88.88 0 010 1.53l-4.11 2.36-4.12 2.36a.88.88 0 01-1.33-.76v-.69a4.48 4.48 0 00-6.71-3.89l-4.51 2.59-4.45 2.55a4.49 4.49 0 000 7.78L31.7 37l4.51 2.59a4.48 4.48 0 006.71-3.89v-.58a.88.88 0 00-.44-.77l-2.1-1.2a.86.86 0 00-1.3.75v1.38a.89.89 0 01-1.33.77l-4.12-2.37-4.11-2.36a.88.88 0 010-1.53l4.11-2.36 4.12-2.36a.88.88 0 011.33.76v.69a4.48 4.48 0 006.71 3.89l4.51-2.59 4.45-2.55a4.49 4.49 0 000-7.75z"></path>
								<rect
									className="fill-transparent stroke-current text-black stroke-2"
									width="79"
									height="4"
									x="1.5"
									y="50.49"
									rx="2"></rect>
							</svg>
							<Typography>Add your favorites to cart</Typography>
						</div>

						<div className="flex flex-col items-center space-y-2 justify-between">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								role="img"
								viewBox="0 0 82 56"
								className="max-h-[100px]"
								aria-label="Step 3">
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M52.51 28.12l4.43 4.45L65.5 24"></path>
								<circle
									className="fill-transparent stroke-current text-black stroke-2"
									cx="31"
									cy="26"
									r="14.5"></circle>
								<circle
									className="fill-transparent stroke-current text-black stroke-2"
									cx="31"
									cy="21.8"
									r="6.09"></circle>
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M20.92 36.42a8.43 8.43 0 018-5.8h4.2a8.45 8.45 0 018 5.8M36.5 52.49h9"></path>
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M10.43 1.5h61.14a3.93 3.93 0 013.93 3.94V50.5h-69V5.45a3.93 3.93 0 013.93-3.93z"></path>
								<rect
									className="fill-transparent stroke-current text-black stroke-2"
									width="79"
									height="4"
									x="1.5"
									y="50.49"
									rx="2"></rect>
							</svg>
							<Typography>
								Log into or create your Afterpay account, instant approval decision
							</Typography>
						</div>

						<div className="flex flex-col items-center space-y-2 justify-between">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								role="img"
								viewBox="14 7 79 79"
								className="max-h-[100px]"
								aria-label="Step 4">
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M50,15.5A34.5,34.5,0,0,0,15.5,50H50Z"></path>
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M15.5,50A34.5,34.5,0,0,0,50,84.5V50Z"></path>
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M50,84.5h0A34.5,34.5,0,0,0,84.5,50H50Z"></path>
								<path
									className="fill-transparent stroke-current text-black stroke-2"
									d="M57,8.5h0V43h34.5A34.5,34.5,0,0,0,57,8.5Z"></path>
							</svg>
							<Typography>
								Your purchase will be split into 4 payments, payable every 2 weeks
							</Typography>
						</div>
					</div>

					<Button
						className="my-4 text-black bg-transparent hover:bg-slate-300 w-full"
						onClick={() => setModalShopPayOpen(false)}
						type="button"
						variant="filled">
						Close
					</Button>
					<Typography component="p" variant="small">
						You must be over 18, a resident of the U.S. and meet additional eligibility criteria to
						qualify. Late fees may apply. Estimated payment amounts shown on product pages exclude
						taxes and shipping charges, which are added at checkout. For complete terms see{' '}
						<a href="https://www.afterpay.com/en-US/installment-agreement" className="underline">
							Installment Agreement.
						</a>{' '}
						Loans to California residents made or arranged pursuant to a California Finance Lenders
						Law license. © 2020 Afterpay
					</Typography>
				</div>
			</ModalBody>
		</Modal>
	);
}

ModalAfterpay.propTypes = {
	modalShopPayOpen: PropTypes.bool.isRequired,
	setModalShopPayOpen: PropTypes.func.isRequired,
};
