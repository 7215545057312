import Typography from '/components/Typography';

import { reactChildren } from '/utils/prop-types';

const Paragraph = ({ children }) => {
	return (
		<Typography className="mb-4" component="p" variant="body">
			{children}
		</Typography>
	);
};

Paragraph.propTypes = {
	children: reactChildren.isRequired,
};

export const p = Paragraph;
