import Divider from '/components/Divider';
import ExpandableDetails from '/components/products/show/product-form/ExpandableDetails';
import Link from 'next/link';
import Markdown from '/components/Markdown';
import PropTypes from 'prop-types';
import ReadMore from '/components/ReadMore';
import ReadMoreList from '/components/ReadMoreList';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { getProductDetails } from '/services/cms';
import { findProductTag, findProductTags } from '/utils/product-tags';
import { getTaggedDetails, getTaggedDiscover } from '/services/static/product-details';
import { useEffect, useState } from 'react';

const getSplitText = (text) => {
	const possibleSplitText = ['DEETS', 'Product Details'];

	// try to split the text on random text that people put in descriptions
	for (let i = 0; i < possibleSplitText.length; i++) {
		const splitWord = possibleSplitText[i];
		const splitText = text.split(splitWord);

		if (splitText.length > 1) {
			return splitText;
		}
	}

	return [text];
};

const ProductDescription = ({
	pdpConfig,
	productTags,
	showProductDetails = true,
	styleNumber,
	text,
}) => {
	const [productDetails, setProductDetails] = useState();
	const [showMore, setShowMore] = useState(pdpConfig.defaultDetailsDrawerOpen);
	const taggedDetail = findProductTag(productTags, 'deets', 1);
	const taggedThemes = findProductTags(productTags, 'theme', 1);
	const taggedTypes = findProductTags(productTags, 'product type', 1);
	const splitText = getSplitText(text);
	const taggedDetails = getTaggedDetails(taggedDetail);
	const taggedDiscovery = getTaggedDiscover(taggedThemes, taggedTypes);
	const shippingMarkdown =
		"**US Shipping** \n\n  Overnight, express, and ground options available at checkout \n\n  **International Shipping**   \n\n Express and economy options available at checkout\n\n **Free Returns & Exchanges**\n\n In addition to our Love Your First Pair or They're Free Guarantee, we offer free returns within 30 days of purchase if you aren't satisfied for any reason.";
	useEffect(() => {
		const fetchProductDetails = async () => {
			const details = await getProductDetails(styleNumber);
			setProductDetails(details);
		};

		if (styleNumber) {
			fetchProductDetails();
		}
	}, [styleNumber]);

	if (pdpConfig.showExpandableDetails) {
		return (
			<ExpandableDetails
				pdpConfig={pdpConfig}
				productDescription={text}
				styleNumber={styleNumber}
				productDetails={<Markdown content={productDetails?.body} />}
				shippingInfo={<Markdown content={shippingMarkdown} />}
			/>
		);
	}

	return (
		<>
			<Divider />
			{splitText[0] && (
				<div className="mb-8">
					<Typography component="h4" variant="subtitle-md">
						Description
					</Typography>
					<Divider />
					<ReadMore text={splitText[0]} />
				</div>
			)}
			{showProductDetails && productDetails && !productDetails?.error && (
				<div className="mb-8">
					<Divider />
					<Typography component="h4" variant="subtitle-md">
						Product Details
					</Typography>
					<Divider />
					{taggedDetails && <ReadMoreList contents={taggedDetails} />}
					{productDetails && (
						<>
							<div className={classNames('overflow-hidden', showMore ? 'h-auto' : 'max-h-[100px]')}>
								<Markdown content={productDetails.body} />
							</div>
							<button className="text-blue-500 underline" onClick={() => setShowMore(!showMore)}>
								{showMore ? 'Show Less' : 'Show More Details'}
							</button>
						</>
					)}
				</div>
			)}
			{taggedDiscovery && (
				<div className="mb-8">
					<Typography variant="body">
						Discover even more&nbsp;
						{taggedDiscovery.map((discovery, i, obj) => {
							if (obj.length - 1 != i) {
								return (
									<Typography variant="body" key={i}>
										<Link href={discovery['link']} passHref alt={discovery['text']}>
											<a>
												<Typography variant="link">{discovery['text']}</Typography>
											</a>
										</Link>
										<Typography variant="body">&nbsp;and&nbsp;</Typography>
									</Typography>
								);
							} else {
								return (
									<Link href={discovery['link']} passHref alt={discovery['text']} key={i}>
										<a>
											<Typography variant="link">{discovery['text']}</Typography>
										</a>
									</Link>
								);
							}
						})}
					</Typography>
				</div>
			)}
		</>
	);
};

ProductDescription.propTypes = {
	pdpConfig: PropTypes.object,
	productTags: PropTypes.array,
	showProductDetails: PropTypes.bool,
	styleNumber: PropTypes.string,
	text: PropTypes.string,
};

export default ProductDescription;
