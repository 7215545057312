import Price from '../Price';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react-lite';
import { useDiscountCodeStore } from '../../state/discount-code-context';
import { useGlobalStore } from '/state/global-context';
import { useEffect, useState } from 'react';

const SubscriptionPrice = ({ ecommPrice, priceDefaultSubscription, productType }) => {
	const discountCodeStore = useDiscountCodeStore();
	const [discountSubscriptionPrice, setDiscountSubscriptionPrice] = useState();
	const [savings, setSavings] = useState();

	const globalStore = useGlobalStore();
	const promotion = globalStore.getPromotionalConfig();
	useEffect(() => {
		if (promotion?.promotionType == 'subscription-discount') {
			setDiscountSubscriptionPrice(priceDefaultSubscription / 2);
			return;
		}
		if (!discountCodeStore) {
			return;
		}

		const discountCodeConfig = discountCodeStore.getCurrentDiscountCodeConfig(productType);
		setDiscountSubscriptionPrice(discountCodeConfig?.subscriptionPrice);
		if (ecommPrice) {
			const savings = Math.round(
				(1 - (discountCodeConfig?.subscriptionPrice || priceDefaultSubscription) / ecommPrice) *
					100,
			);
			setSavings(savings);
		}
	}, [discountCodeStore?.currentDiscountCode, productType]);

	return (
		<div className="flex gap-1">
			<Price price={discountSubscriptionPrice || priceDefaultSubscription} />
			{savings && <span>({savings}% Off)</span>}
		</div>
	);
};

SubscriptionPrice.propTypes = {
	ecommPrice: PropTypes.PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	priceDefaultSubscription: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	productType: PropTypes.string,
};

export default observer(SubscriptionPrice);
