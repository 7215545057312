import Button from '/components/Button';
import PropTypes from 'prop-types';
import Typography from '/components/Typography';
import classNames from '/utils/class-names';
import { useState } from 'react';

const ReadMore = ({ className = '', component = 'span', text, truncateAt = 300 }) => {
	const [showAllText, setShowAllText] = useState(false);

	const toggleShowAll = () => {
		setShowAllText(!showAllText);
	};

	if (!text || text.length < truncateAt) {
		return (
			<Typography className={className} component={component} variant="body">
				{text}
			</Typography>
		);
	}

	const introText = text.substring(0, truncateAt);
	const restOfText = text.substring(truncateAt);

	return (
		<Typography
			className={classNames(className, ' my-4', component === 'h2' ? ' inline' : '')}
			component={component}
			variant="body">
			<span>{introText}</span>
			<span className={classNames(showAllText ? 'hidden' : 'visible')}>...</span>
			<span className={classNames(showAllText ? 'visibile' : 'hidden')}>{restOfText}</span>
			<Button
				className={classNames(component === 'h2' ? 'inline' : '', 'ml-1 ')}
				onClick={() => {
					toggleShowAll();
				}}
				variant="link">
				{showAllText ? 'Show Less' : 'Read More'}
			</Button>
		</Typography>
	);
};

ReadMore.propTypes = {
	className: PropTypes.string,
	component: PropTypes.string,
	text: PropTypes.string.isRequired,
	truncateAt: PropTypes.number,
};

export default ReadMore;
