import Price from '../../../Price';
import Typography from '/components/Typography';
import { findProductTag } from '/utils/product-tags';
import { getSubscriptionForProductType } from '/services/static/subscription/subscriptions-ordergroove';

import {
	DEFAULT_PRICE_MENS_BOXER_COOLING_DECIMAL,
	DEFAULT_PRICE_WOMENS_THONG_COOLING_DECIMAL,
} from '/services/static/default-prices';
import {
	checkAvailableSubscription,
	findAlternateSubscriptionProductType,
} from '/services/static/subscription/utils';

const renameLabels = {
	bimonthly: 'Every 2 Months',
	quarterly: 'Every 3 Months',
};

function getRadioLabel(
	type,
	product,
	compareAtPrice,
	sellingPlan,
	discountedSubscriptionPrice,
	promotion,
) {
	if (promotion && promotion?.promotionType === 'subscription-discount') {
		discountedSubscriptionPrice = sellingPlan.recurringPrice * 0.5;
	}
	if (type === 'product') {
		return (
			<Typography variant="body">
				Get once{' '}
				<span className="font-bold">
					<Price price={product.prices[0]} />
				</span>{' '}
				{compareAtPrice > product.prices[0] && (
					<span className="line-through">
						<Price price={compareAtPrice} />
					</span>
				)}
			</Typography>
		);
	}

	if (type === 'selling-plan' && product.productType === 'Laundry Detergent Sheets') {
		return (
			<Typography variant="heading-xs">
				{renameLabels[sellingPlan.frequency] || sellingPlan.frequency}
			</Typography>
		);
	}

	if (type === 'selling-plan') {
		const savings = sellingPlan.recurringPrice
			? Math.round((1 - sellingPlan.recurringPrice / product?.prices[0]) * 100)
			: 0;
		const savingsPromotional = discountedSubscriptionPrice
			? Math.round((1 - discountedSubscriptionPrice / product?.prices[0]) * 100)
			: 0;
		return (
			<Typography variant="body">
				{discountedSubscriptionPrice ? 'Subscription Offer' : 'Subscribe & Save'}
				<span className="font-bold ml-1">
					<Price price={discountedSubscriptionPrice || sellingPlan.recurringPrice} />
				</span>
				{savingsPromotional > 0 && <span className="pl-2">Save {savingsPromotional}%</span>}
				{!savingsPromotional && <span className="pl-2">({savings}% Off) + Free Shipping</span>}

				{promotion && promotion?.promotionType === 'subscription-discount' && (
					<span className="pl-2">50% Off Labor Day Sub Sale </span>
				)}
				{discountedSubscriptionPrice && (
					<>
						{' '}
						(Renews {sellingPlan.frequency} at <Price price={sellingPlan.recurringPrice} />)
					</>
				)}
			</Typography>
		);
	}
}

const generateSubscriptionSettings = (
	product,
	showAnnualOption,
	showOnlySubscriptionOptions,
	countryCode,
	promotion,
	discountedPrice,
) => {
	const settings = {
		isSubscriptionAvailable: checkAvailableSubscription(product, countryCode),
	};

	const alternateSubscriptionProductType = findAlternateSubscriptionProductType(
		product.productType,
	);

	// the product price is needed if we have an alternate subscription type.
	if (alternateSubscriptionProductType && !product.prices) {
		return settings;
	}

	const subscriptionData = getSubscriptionForProductType(
		alternateSubscriptionProductType || product.productType,
	);

	if (subscriptionData?.sizes) {
		settings.validSizes = subscriptionData.sizes;
	}

	if (subscriptionData?.nameShort) {
		settings.subscriptionNameShort = subscriptionData.nameShort;
	}

	if (product.productType === 'Boxers - Cooling - Fly') {
		settings.compareAtPrice = DEFAULT_PRICE_MENS_BOXER_COOLING_DECIMAL;
	} else if (product.productType === 'Thongs - Cooling') {
		settings.compareAtPrice = DEFAULT_PRICE_WOMENS_THONG_COOLING_DECIMAL;
	} else if (product.subscriptionCompareAtPrice) {
		settings.compareAtPrice = product.subscriptionCompareAtPrice;
	}

	// we're going to show a product selector for a different product type, we need to handle getting the settings for it
	if (alternateSubscriptionProductType) {
		let productFlavor = findProductTag(product.tags, 'flavor', 1, 'surprise');
		productFlavor = productFlavor?.toLowerCase() || 'default';

		if (subscriptionData.collections && subscriptionData.collections[productFlavor]) {
			settings.isAlternateSubscripton = true;
			settings.alternateSubscriptionProductType = alternateSubscriptionProductType;
			settings.alternateSubscriptionCollection = subscriptionData.collections[productFlavor];
		}
	}

	settings.radios = [];

	if (
		showOnlySubscriptionOptions === false &&
		!product.tags.includes('feature: Subscription Exclusive')
	) {
		const compareAtPrice = parseFloat(product.compareAtPrices[0]);
		const defaultEcomm = product.sellingPlans && product.sellingPlans.length > 0;
		settings.radios.push({
			checked: !defaultEcomm,
			label: getRadioLabel('product', product, compareAtPrice),
			value: 'product',
		});
	}

	if (
		settings.isSubscriptionAvailable &&
		(product.productType === 'Boxers - Cooling - Fly' || product.productType === 'Thongs - Cooling')
	) {
		//this is jank need to make better in rewrite
		settings.radios[0].checked = true;
		settings.radios.push({
			checked: false,
			label: (
				<Typography variant="body">
					Subscribe for{' '}
					<span className="font-bold">
						<Price price={product.prices[0]} /> (includes 2 pairs + free shipping)
					</span>
				</Typography>
			),
			value: 'alternate-subscription',
		});
	}

	if (settings.isSubscriptionAvailable && product.sellingPlans && product.sellingPlans.length > 0) {
		const validFrequencies = [];
		let defaultFrequency = 'monthly';

		if (product.productType === 'Laundry Detergent Sheets') {
			validFrequencies.push('monthly');
			validFrequencies.push('bimonthly');
			validFrequencies.push('quarterly');
			defaultFrequency = 'bimonthly';
		} else if (product.productType == "Men's T-Shirts") {
			validFrequencies.push('monthly');
			validFrequencies.push('quarterly');
		} else {
			validFrequencies.push('monthly');

			if (showAnnualOption === true) {
				validFrequencies.push('annually');
			}
		}

		const sellingPlans = product.sellingPlans.filter((sellingPlan) => {
			return validFrequencies.includes(sellingPlan.frequency);
		});
		//leaving this here in case we ever want to go back to it but for now this is fine
		// const lastSellingPlan = sellingPlans[sellingPlans.length - 1];
		const defaultSellingPlan = sellingPlans.find(
			(sellingPlan) => sellingPlan.frequency === defaultFrequency,
		);
		if (defaultSellingPlan) {
			settings.defaultSellingPlan = defaultSellingPlan;
			settings.defaultCartType = `selling-plan-${defaultSellingPlan.id}`;
		}

		sellingPlans.forEach((sellingPlan) => {
			settings.radios.push({
				checked: defaultSellingPlan && defaultSellingPlan.id === sellingPlan.id,
				label: getRadioLabel('selling-plan', product, 0, sellingPlan, discountedPrice, promotion),
				value: `selling-plan-${sellingPlan.id}`,
			});
		});
	}

	return settings;
};

module.exports = {
	generateSubscriptionSettings,
};
