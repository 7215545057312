import * as markdownComponents from './markdown/components';

import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import remarkGfm from 'remark-gfm';

const Markdown = ({ content }) => {
	if (!content || content === '') {
		return null;
	}

	return (
		<ReactMarkdown
			rehypePlugins={[rehypeSlug]}
			remarkPlugins={[remarkGfm]}
			components={markdownComponents}>
			{content}
		</ReactMarkdown>
	);
};

Markdown.propTypes = {
	content: PropTypes.string,
};

export default Markdown;
